import { applyPercentages, limitLineups } from './gen.js';

let limit = 5000;
export function setLimit(n) {
  limit = n;
}
export const getLimit = () => limit;

/** LineupGen lifecycle hook
 * @typedef {function} LifecycleHook
 * @param {object} state - the current state for the generator
 */

/** LineupGen config object
 * @typedef {Object} Lifecycle
 * @property {object} additionalState - any params needed to use in filters
 * @property {object} additionalProps - add properties to each item in `items` array
 * @property {LifecycleHook} preProcess - processing that should happen before the generator runs
 * @property {LifecycleHook} shouldExitEarly - a callback that returns whether to exit the current combination's loop or not
 * @property {LifecycleHook} beforeComboLoop - pre-loop processing, e.g. setting state to be used in comboLoop() and afterComboLoop()
 * @property {LifecycleHook} comboLoop - hook into the current combination's loop
 * @property {LifecycleHook} afterComboLoop - processing immediately after combo loop, such as for checking sums from combo loop
 * @property {LifecycleHook} processing - that should happen after the generator is finished running
 */

export const ShowdownConfig = {
  additionalState: {
    budget: 50000,
    slots: 6,
    minBudgetSpend: 0,
    minCptValue: 0,
    minCptValExclusions: [],
    minPriorityScore: 0
  },
  exportHeaders: [
    'CPT',
    'FLEX',
    'FLEX',
    'FLEX',
    'FLEX',
    'FLEX',
    'Summed Ranking',
    'Budget Spend'
  ],
  additionalProps(i) {
    return {
      // number of lineups where player is captain
      appearances: 0,
      // set indexes for easy reference in `applyPercentages()`
      origIndex: i
    };
  },
  preProcess: state => {
    for (let i = 0; i < state.items.length; ++i) {
      const o = state.items[i];

      if (
        !o.excludeAsCpt &&
        o.value * 1.5 < state.minCptValue &&
        !state.minCptValExclusions.includes(o.id)
      ) {
        o.excludeAsCpt = true;
      }
    }
  },
  shouldExitEarly: state =>
    !state.cur.some(el => el.team !== state.cur[0].team), // filter combinations that don't include both teams. Loops current combo
  comboLoop: (i, state) => {
    // every combination, when a captain is added, is == r combinations
    state.count++;

    if (state.cur[i].excludeAsCpt) {
      return;
    }

    // sum player values to test if over budget
    // sum player priorities to test if meeting minimum
    let vSum = 0;
    let pSum = 0;

    // move captain to first
    const players = new Array(state.slots);
    players[0] = state.cur[i];
    // * loop new combo, add remaining players
    for (let j = 0, slot = 1; j < state.cur.length; ++j) {
      pSum += state.cur[j].priority;
      // if captain, ignore
      if (j == i) {
        // captain's multiplier
        vSum += state.cur[i].value * 1.5;
        continue;
      }

      players[slot] = state.cur[j];
      vSum += state.cur[j].value;
      slot++;
    }

    if (
      vSum <= state.budget &&
      vSum >= state.minBudgetSpend &&
      pSum >= state.minPriorityScore
      /* || topPriorityPlayers.includes(state.cur[i].id) */
    ) {
      // matches all criteria
      state.viableCount++;
      // times the player appeared as captain in the filtered lineups
      const flexPlayer = state.items[state.cur[i].origIndex];
      flexPlayer.appearances++;
      // add priority sum and value sum to combo array
      state.combos.push({ players, pSum, vSum });
    }
  },
  postProcess: state => {
    const percRes = applyPercentages(state.combos, state.items, limit);
    const truncated = limitLineups(percRes, limit);
    return {
      combos: percRes,
      percFilterCount: percRes.length,
      truncatedCount: truncated
    };
  },
  /**
   * Converts an array of lineup objects to an array of arrays to
   * easily apply custom headers in `lineupsToSheet()`
   * @param {object[]} combos - lineup objects
   * @returns {string[][]} - array of arrays where inner array represents
   * names and stats for a lineup
   */
  combosToAoA(combos) {
    const comboNamesOnly = [];
    for (let i = 0; i < combos.length; ++i) {
      const c = combos[i];
      const row = [];
      // captain always at index 0
      row.push(
        `${c.players[0].firstName} ${c.players[0].lastName} (${c.players[0].cptId})` /* (${this.players.selectedCpt[
            c.players[0].origIndex
          ].id || `NULL`})` */
      );

      for (let j = 1; j < c.players.length; ++j) {
        const p = c.players[j];
        // format resulting name for entry into the DKPlayers spreadsheet for DraftKings
        row.push(`${p.firstName} ${p.lastName} (${p.flexId})`);
      }
      // append lineup players' priority sum and total lineup budget spend
      row.push(c.pSum, c.vSum);
      comboNamesOnly.push(row);
    }
    return comboNamesOnly;
  }
};

export const ClassicConfig = {
  // * lineup requirements
  // 1 QB, 2 RB, 3 WR, 1 TE, 1 FLEX(RB/WR/TE), 1 DST
  // atleast 1 player from a different game (within the slate)
  additionalState: {
    budget: 50000,
    slots: 9
  },
  exportHeaders: [
    'QB',
    'RB',
    'RB',
    'WR',
    'WR',
    'WR',
    'TE',
    'FLEX',
    'DST',
    'Budget Spend'
  ],
  shouldExitEarly: state => !hasDiffGames(state.cur), // must be of two diff nfl games
  beforeComboLoop: state => {
    state.vSum = 0;
    state.lineup = {
      QB: null,
      RB: [],
      WR: [],
      TE: null,
      FLEX: null,
      DST: null
    };
  },
  comboLoop: (i, state) => {
    state.vSum += state.cur[i].value;
    const slot = state.cur[i].position;

    if (state.lineup[slot]) {
      if (
        state.lineup[slot].length !== undefined &&
        state.lineup[slot].length < (slot === 'RB' ? 2 : slot === 'WR' ? 3 : -1)
      ) {
        state.lineup[slot].push(state.cur[i]);
      } else if (!state.lineup.FLEX) {
        // all possible slots for this position are now filled
        state.lineup.FLEX = state.cur[i];
      }
    } else {
      // empty single slot, add player
      state.lineup[slot] = state.cur[i];
    }
  },
  afterComboLoop: state => {
    state.count++;
    const l = state.lineup;

    // validate lineup
    if (
      state.vSum >= state.budget ||
      !(l.QB && l.RB.length == 2 && l.WR.length == 3 && l.TE && l.FLEX && l.DST)
    ) {
      return;
    }

    state.viableCount++;
    state.combos.push({
      players: [l.QB, ...l.RB, ...l.WR, l.TE, l.FLEX, l.DST],
      vSum: state.vSum
    });
  },
  postProcess: state => ({ truncatedCount: limitLineups(state.combos, limit) })
};

export const TiersConfig = players => ({
  // * lineup requirements
  // 1 player from each tier (# of tiers may vary)
  // atleast 1 player from a different game (within the slate)
  additionalState: {
    slots: getTierCount(players) || undefined
  },
  exportHeaders: [],
  shouldExitEarly: state => !hasDiffGames(state.cur) // must be of two diff nfl games
});

// * assumes tiers are "Tier 1, Tier 2, etc." and the numbers
// * are used to find a max, but it could be "B, LEX, R, etc."
// TODO: Determine different tier types and count accordingly, maybe adding unique strings to array and count that
export function getTierCount(players) {
  let max = 0;
  for (let i = 0; i < players.length; ++i) {
    let num;
    const tier = players[i].rosterSlots.find(s => {
      const str = s.substring(1);
      num = +s.substring(1);
      return num;
    });

    if (tier && num > max) {
      max = num;
    }
  }

  return max;
}

export function getConfig(contestType, allPlayers) {
  const t = contestType.toLowerCase().split(' ')[0];
  return t === 'showdown' || t === 'kfc'
    ? ShowdownConfig
    : t === 'classic'
    ? ClassicConfig
    : t.includes('tiers')
    ? TiersConfig(allPlayers)
    : null;
}

const hasDiffGames = arr => arr.some(el => el.gameId != arr[0].gameId);
