<template>
  <div id="genHistory">
    <div></div>
    <details>
      <summary>Lineup {{ selectedPlayers.length }}/6</summary>
      <ul ref="players" @change="playersChange">
        <li v-for="p in players" :key="p.id">
          <label>
            <input type="checkbox" /><span></span> {{ p.firstName }}
            {{ p.lastName }}
          </label>
        </li>
      </ul>
    </details>
    <button
      @click="
        checkHistoryForLineup(
          selectedPlayers.map(p => p.id),
          genRuns
        )
      "
    >
      Search
    </button>
    <div v-if="hasSearched">
      <p>
        <b>{{ matches }}</b> sets generated this lineup.
      </p>
    </div>
    <ul @click="genRunClicked">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <li v-for="r in genRuns" :class="{ match: r.includesLineup }">
        <div>
          <p>{{ r.playerFilters.length }} Player Filters</p>
        </div>
        <!-- <button>X</button> -->
      </li>
    </ul>
  </div>
</template>

<script>
import { getConfig } from '../components/lineup/config.js';
import { LineupGen } from '../components/lineup/gen.js';
import { getClickedListElement } from '../util/util.js';

export default {
  name: 'History',
  data() {
    return {
      genRuns: [],
      matches: 0,
      hasSearched: false,
      selectedPlayers: [],
      lineupSlots: 6
    };
  },
  computed: {
    players() {
      return this.getAllPlayers();
    }
  },
  async created() {
    this.genRuns = await this.getLineupRuns();
  },
  methods: {
    async getLineupRuns() {
      this.historyLoading = true;
      return this.$store
        .dispatch(
          `getLineupGenFilters`,
          this.$store.state.selectedSlate.forDate
        )
        .finally(() => (this.historyLoading = false));
    },
    getGeneratorParams(filterSet, players) {
      // playerFilters will contain filters for every included player
      const pfs = filterSet.playerFilters;
      const selected = [];
      const mustBeInLineup = [];

      for (let i = 0; i < players.length; ++i) {
        for (let j = 0; j < pfs.length; ++j) {
          if (players[i].id === pfs[j].playerExternId) {
            players[i].exclude = false;
            players[i].excludeAsCpt = pfs[j].excludeAsCpt;
            players[i].mustBeInLineup = pfs[j].mustInclude;
            players[i].targetPerc = pfs[j].percAsCpt;
            players[i].priority = pfs[j].priority;

            selected.push(players[i]);
            pfs[j].mustInclude && mustBeInLineup.push(pfs[j].playerExternId);

            break;
          }
        }
      }

      return {
        config: {
          minBudgetSpend: filterSet.minBudgetSpend,
          minCptValue: filterSet.minCptVal,
          minPriorityScore: filterSet.minPriorityScore
        },
        players: selected,
        mustBeInLineup
      };
    },
    getAllPlayers() {
      const players = this.$store.state.selectedSlate.players;
      const all = [];
      // set expected properties ('position', 'id', 'team', 'name', 'value' come from API)
      for (let i = 0; i < players.length; ++i) {
        all.push({
          ...players[i],
          mustBeInLineup: false,
          exclude: true,
          excludeAsCpt: true,
          targetPerc: 0,
          priority: 1
        });
      }

      return all.sort((a, b) => b.value - a.value);
    },
    async checkHistoryForLineup(lineup, runs) {
      const runsWithLineup = [];
      const allPlayers = this.getAllPlayers();
      const config = getConfig(
        this.$store.state.selectedSlate.contests[0].type,
        allPlayers
      );

      for (let i = 0; i < runs.length; ++i) {
        runs[i].includesLineup = false;
        const params = this.getGeneratorParams(runs[i], allPlayers);

        // generator won't produce the given lineup if one of the given players isn't selected
        if (lineup.some(id => !params.players.some(p => p.id === id))) {
          continue;
        }

        config.additionalState = {
          ...config.additionalState,
          ...params.config
        };
        const { combos } = LineupGen.run(params.players, config);

        for (let j = 0; j < combos.length; ++j) {
          let includes = true;
          for (let k = 0; k < lineup.length; ++k) {
            if (combos[j].players.findIndex(p => p.id === lineup[k]) == -1) {
              includes = false;
              break;
            }
          }

          if (includes) {
            runs[i].includesLineup = true;
            runsWithLineup.push(runs[i]);
            break;
          }
        }
      }

      this.$nextTick(() => {
        this.hasSearched = true;
        this.genRuns = [...runs];
        this.matches = runsWithLineup.length;
      });
      return runsWithLineup;
    },
    genRunClicked(e) {
      const i = getClickedListElement(e);
      const all = this.getAllPlayers();
      if (i !== undefined) {
        this.$emit(`update:genParams`, {
          ...this.getGeneratorParams(this.genRuns[i], all),
          all
        });
        this.$router.go(-1);
      }
    },
    playersChange(e) {
      if (
        e.target.checked &&
        this.selectedPlayers.length + 1 > this.lineupSlots
      ) {
        e.target.checked = false;
        alert(`All slots filled.`);
        return;
      }

      const s = [];
      const { children } = this.$refs.players;
      for (let i = 0; i < children.length; ++i) {
        const cb = children[i].querySelector(`label > input`);
        // console.log(cb, cb.checked, cb.value);
        if (cb.checked) {
          s.push(this.players[i]);
        }
      }
      this.selectedPlayers = s;
    }
  }
};
</script>

<style>
#genHistory {
  overflow: hidden;
  flex-grow: 1;
}
#genHistory > ul li {
  border-left: 4px solid transparent;
  display: flex;
  flex-direction: column;
}
#genHistory > ul li.match {
  border-color: var(--clr-lt-accent);
}
#genHistory > ul li > div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#genHistory > ul li > div > p {
  padding-left: 0.5rem;
}

#genHistory button + div > p {
  color: var(--clr-lt-accent);
  padding: 1rem;
}

#genHistory > ul li + li::before {
  content: '';
  border-top: 1px solid var(--clr-dk-accent);
  padding: 0.25rem;
}

#genHistory details {
  margin: 1rem 0;
}
#genHistory details > ul {
  max-height: 28vh;
  overflow: auto;
}
</style>
