/** Recursive generate function
 * @param {number} start - start index
 * @param {number} end - end index
 * @param {number} index - current index
 * @param {number} r - number of slots in a combination
 * @param {object} lifecycle - lifecycle hooks
 * @param {object} state - the current state for the generator
 */
const runCycle = (start, end, index, r, lifecycle, state) => {
  // current combination ready to be recorded
  if (index == r) {
    if (lifecycle.shouldExitEarly(state)) {
      state.count++;
      return;
    }

    lifecycle.beforeComboLoop(state);
    // * loop current combo
    for (let i = 0; i < state.cur.length; ++i) {
      lifecycle.comboLoop(i, state);
    }

    lifecycle.afterComboLoop(state);

    return;
  }

  // replace index with all possible elements. The condition
  // "end-i+1 >= r-index" makes sure that including one element
  // at index will make a combination with remaining elements
  // at remaining positions
  for (let i = start; i <= end && end - i + 1 >= r - index; ++i) {
    state.cur[index] = state.items[i];
    runCycle(i + 1, end, index + 1, r, lifecycle, state);
  }
};

export const LineupGen = {
  /** Find lineup combinations.
   * @param {Lifecycle} config
   */
  run(items, { additionalState: { slots, ...additionalState }, ...config }) {
    // set lifecycle hooks
    const lifecycle = {
      additionalProps: index => {},
      preProcess: state => {},
      shouldExitEarly: state => true,
      beforeComboLoop: state => {},
      comboLoop: (i, state) => {},
      afterComboLoop: state => {},
      postProcess: state => {},
      // also has `combosToAofA()`
      ...config
    };

    const state = {
      count: 0, // total combinations regardless of criteria
      viableCount: 0, // combinations that meet criteria
      combos: [],
      // make a copy of items, with additional helper properties
      items: items.map((o, i) => ({
        ...o,
        ...lifecycle.additionalProps(i)
      })),
      cur: [],
      ...additionalState
    };

    // // remove excluded items
    // for (let i = 0, count = 0; i < state.items.length; ++i) {
    //   if (!state.items[i].exclude || state.items.mustBeInLineup) {
    //     state.items.push({
    //       ...o,
    //       ...lifecycle.additionalProps(count)
    //     });
    //     count++;
    //   }
    // }

    lifecycle.preProcess(state);

    runCycle(0, state.items.length - 1, 0, slots, lifecycle, state);

    const postProcess = lifecycle.postProcess(state);

    return {
      totalCount: state.count,
      viableCount: state.viableCount,
      combos: state.combos,
      ...postProcess
    };
  }
};

/**
 * While each lineup's captain currently appears in more than a target percentage
 * amount of lineups, remove the lineup. The target percentage is based on their
 * total appearances amount, not total lineups amount. Looping starts at the end
 * to remove least-value lineups.
 *
 * ! Mutates input array
 * @param {*[]} lineups
 * @param {*[]} playerArr - list of player objects, each containing at least `name`, `appearances`, and `targetPerc`
 */
export function applyPercentages(lineups, playerArr) {
  // const targetPercentOfTotal = lineups.length * targetPerc / 100;
  // console.log(`${targetPerc}% of ${lineups.length} lineups: ${targetPercentOfTotal}\n\n`);

  // console.log(`before filter`);
  for (const p of playerArr) {
    // console.log(
    //   `    ${p.name}: ${p.appearances} appearances, ${(
    //     (p.appearances * 100) /
    //     lineups.length
    //   ).toFixed(2)}%`
    // );

    // the percentage cap a player captain should appear
    p.targetAppearanceCount = Math.ceil((p.appearances * p.targetPerc) / 10);
  }
  const newLineups = [];

  for (let i = 0; i < lineups.length; ++i) {
    const index = lineups.length - 1 - i;
    const cpt = lineups[index].players[0];
    const srcPlayer = playerArr[cpt.origIndex];

    if (srcPlayer.appearances > srcPlayer.targetAppearanceCount) {
      srcPlayer.appearances--;
      continue;
    }

    newLineups.push(lineups[index]);
  }

  // console.log(`after filter`);
  // for (const p of playerArr) {
  //   console.log(
  //     `    ${p.name}: ${p.appearances} appearances, ${(
  //       (p.appearances * 100) /
  //       lineups.length
  //     ).toFixed(2)}%`
  //   );
  // }

  return newLineups.reverse();
}

export function limitLineups(lineups, max) {
  // throw out lineups over the max limit to reduce final memory usage
  let truncatedCount = 0;
  if (max > 0 && lineups.length > max) {
    truncatedCount = lineups.length - max;
    lineups.length = max;
  }
  return truncatedCount;
}
